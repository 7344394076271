<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="
                  () => {
                    showModalCreate = true;
                    form = {};
                  }
                "
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="menu"
          dataKey="_id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Menu List</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
              </span>
            </div>
          </template>

          <Column field="title" header="Sub-Menu Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.title }}
            </template>
          </Column>
          <Column field="menu_id.route" header="Menu Path" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.menu_id.route }}
            </template>
          </Column>
          <Column field="route" header="Sub-Menu Path" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.route }}
            </template>
          </Column>
          <Column field="icon" header="Icon" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.icon }}
            </template>
          </Column>
          <Column field="order" header="Menu Order" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.order }}
            </template>
          </Column>
          <Column field="is_active" header="Active Status" :sortable="true">
            <template #body="slotProps">
              {{ `${slotProps.data.is_active}` ? "Active" : "Not Active" }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Actions">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="showEditModal(slotProps.data)" />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mt-2"
                @click="confirmDeleteItem(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- create -->
        <Dialog v-model:visible="showModalCreate" :style="{ width: '450px' }" header="Add Sub-Menu" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Sub-Menu Name :</label>
            <InputText id="name" v-model.trim="form.name" required="true" autofocus />
          </div>
          <div class="field">
            <label for="route">Route :</label>
            <InputText id="route" v-model.trim="form.route" required="true" autofocus />
          </div>
          <div class="field">
            <label for="icon">Icon :</label>
            <InputText id="icon" v-model.trim="form.icon" required="true" autofocus />
          </div>
          <div class="field">
            <label for="order">Order :</label>
            <InputNumber v-model="form.order" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="order">Menu :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.menu_id"
              :options="menu_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Active :</label>
            <InputSwitch v-model="form.is_active" />
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />

            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="createItem"
            />
          </template>
        </Dialog>

        <!-- Edit Modal Item -->
        <Dialog v-model:visible="showModalEdit" :style="{ width: '450px' }" header="Add Sub-Menu" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Sub-Menu Name :</label>
            <InputText id="name" v-model.trim="form.title" required="true" autofocus />
          </div>
          <div class="field">
            <label for="route">Route :</label>
            <InputText id="route" v-model.trim="form.route" required="true" autofocus />
          </div>
          <div class="field">
            <label for="icon">Icon :</label>
            <InputText id="icon" v-model.trim="form.icon" required="true" autofocus />
          </div>
          <div class="field">
            <label for="order">Order :</label>
            <InputNumber v-model="form.order" showButtons mode="decimal"></InputNumber>
          </div>
          <div class="field">
            <label for="order">Menu :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.menu_id"
              :options="menu_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <div class="field center-items-vertical">
            <label style="margin: 0px" for="active">Active :</label>
            <InputSwitch v-model="form.is_active" />
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />

            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="editItem"
            />
          </template>
        </Dialog>

        <!-- Delete Modal Item -->
        <Dialog
          v-model:visible="showModalDeleteItem"
          :style="{ width: '450px' }"
          header="Delete Sub-Menu Confirm ?"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span
              >Are you sure you want to delete Sub-Menu <b>{{ temp_item.title }}</b> From Menu
              <b>{{ temp_item.menu_id.name }}</b> ?</span
            >
          </div>
          <template #footer>
            <Button @click="showModalDeleteItem = !showModalDeleteItem" label="No" icon="pi pi-times" class="p-button-text" />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="deleteItem(temp_item._id)"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import MenuApi from "../../api/Menu";
import SubMenuApi from "../../api/SubMenu";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      showModalCreate: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalEdit: false,
      selectedRole: {},
      deleteProductDialog: false,
      menu: [],
      temp_item: {},
      menu_option: [],
      selectedUsers: [],
      isLoading: false,
      form: {
        name: "",
        route: "",
        icon: "",
        is_active: false,
        order: 1,
        menu_id: "",
      },
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.getMenu();
    this.getSubMenu();
  },

  methods: {
    async getMenu() {
      try {
        const response = await MenuApi.Get();
        if (response.data.status === 200) {
          response.data.data.map((sub) => {
            this.menu_option.push({ label: sub.name, value: sub._id });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getSubMenu() {
      try {
        const response = await SubMenuApi.Get();
        if (response.data.status === 200) {
          this.menu = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async createItem() {
      this.isLoading = true;
      try {
        const response = await SubMenuApi.Add({
          ...this.form,
          menu_id: this.form.menu_id.value,
        });
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New Sub-Menu Created!",
            life: 3000,
          });

          // Reset Form
          this.form = {};

          this.getSubMenu();
          this.isLoading = false;
          this.showModalCreate = false;
        } else {
          this.form = {};

          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: response.data.message,
            life: 3000,
          });
          this.isLoading = false;
          this.showModalCreate = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async showEditModal(item) {
      try {
        this.form = {
          ...item,
          menu_id: { label: item.menu_id.name, value: item.menu_id._id },
        };
        this.showModalEdit = true;
      } catch (error) {
        console.log(error);
      }
    },

    async editItem() {
      try {
        this.isLoading = true;
        const response = await SubMenuApi.Update(this.form._id, {
          ...this.form,
          menu_id: this.form.menu_id.value,
        });

        if (response.data.status === 200) {
          this.getSubMenu();
          this.form = {};
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Menu Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_item = user;
    },

    async deleteItem(id) {
      try {
        this.isLoading = true;
        const response = await SubMenuApi.Delete(id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_item.username} Deleted!`,
            life: 3000,
          });
          this.getSubMenu();
          this.temp_item = {};
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteSelected() {
      this.showModalDelete = true;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";

.center-items-vertical {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
